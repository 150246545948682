<template>
  <div>
    <div class="mt-10 px-4">
      <!-- background -->
      <div class="background-ball-big"></div>
      <div class="background-ball-small"></div>

      <div class="pt-4 title">
        <h3>{{ layouts.data.title }}</h3>
      </div>

      <div class="d-flex flex-column justify-center px-2">
        <v-icon color="var(--theme_primary)" size="66" class="mb-5">{{ layouts.data.icon }}</v-icon>
        <div class="f-s-16">
          {{ layouts.data.primaryText }}
        </div>
      </div>

      <div class="px-2 f-s-16" v-if="layouts.data.radioGroupDisplay">
        <v-radio-group v-model="layouts.data.radioGroupValue">
          <v-radio
            v-for="(item, index) in layouts.settings.radioGroup"
            :key="index"
            :label="item.text"
            color="var(--theme_primary)"
            :value="item.value"
          ></v-radio>
        </v-radio-group>
      </div>

    </div>
    <div class="footer">
      <wap-btn
        :height="50"
        width="100%"
        @click="onClickContinue"
      >Continue
      </wap-btn>
    </div>
  </div>
</template>

<script>
import WapBtn from '@/components/base/WapBtn'
import { DataType } from '@/assets/js/util'

export default {
  name: 'Requirement',
  components: {
    WapBtn
  },
  data () {
    return {
      layouts: {
        data: {
          title: '',
          icon: '',
          primaryText: '',
          radioGroupValue: 1,
          radioGroupDisplay: false
        },
        settings: {
          radioGroup: [
            {
              text: 'Upload Bank Statements',
              value: 1
            },
            {
              text: 'Change Bank Account and Re-Authorize Instant Bank Verification',
              value: 2
            }
          ]
        }
      }
    }
  },
  mounted () {
    const type = this.$store.getters.getLoan.incompleteScriptType
    if (type === DataType.NOIA_SITUATION.NOT_QUALIFIED.value) {
      this.layouts.data.title = 'Change and Re-authorize Bank Account'
      this.layouts.data.icon = 'mdi-bank-transfer'
      this.layouts.data.primaryText = 'To ensure information security and faster process, we recommend you use a new bank account for the Instant Bank Verification authorization process. Your convenience and information security are our utmost concern.'
    } else {
      this.layouts.data.title = 'Bank Account Verification Options'
      this.layouts.data.icon = 'mdi-folder-upload-outline'
      this.layouts.data.primaryText = 'To ensure information security and expedite the process, please continue the process with following options:'
      this.layouts.data.radioGroupDisplay = true
    }
  },
  methods: {
    onClickContinue () {
      const type = this.$store.getters.getLoan.incompleteScriptType
      const UPLOAD_BANK_STATEMENT = 1
      // const CHANGE_OR_REAUTHORIZE = 2
      if (type === DataType.NOIA_SITUATION.NOT_QUALIFIED.value) {
        this.$router.push('/guidance')
      } else {
        this.layouts.data.radioGroupValue === UPLOAD_BANK_STATEMENT ? this.$router.push('/upload-bank-statement') : this.$router.push('/guidance')
      }
    }
  }
}
</script>

<style lang='sass' scoped>
.background-ball-big
  position: absolute
  top: -60vw
  left: -5vw
  width: 110vw
  height: 110vw
  background-color: var(--theme_primary_opacity_high)
  border-radius: 50%

.background-ball-small
  position: absolute
  top: -55vw
  right: -58vw
  width: 100vw
  height: 100vw
  background-color: var(--theme_primary_opacity_high)
  border-radius: 50%

.title
  height: 22vh

</style>
